$datepicker__text-color: var(--alto);
$datepicker__border-radius: 5px;
$datepicker__border-color: var(--riverBed);
$datepicker__background-color: var(--riverBed);
$datepicker__header-color: var(--alto);
$datepicker__selected-color: #68767c;

@import '~react-datepicker/src/stylesheets/datepicker.scss';

.react-datepicker__header {
  background-color: var(--riverBed);
}

.react-datepicker-wrapper {
  width: 100%;

  .react-datepicker__input-container {
    width: 100%;
    display: grid;

    .react-datepicker__close-icon {
      right: 65px;
      transform: scale(1.7);
      padding-right: 1px;
      padding-left: 50px;

      &::after {
        background-color: transparent;
        line-height: 21px;
      }
    }
  }
}

.react-datepicker-popper {
  .react-datepicker__month-dropdown-container {
    padding-right: 25px;
  }
}
